module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"ODE0N2IyNzItZjFkMy00YjFkLWJhYmMtNjNhY2MxN2ZkODhlNjM3NDQ4NDY4NDkwMjUyMDA2","defaultLang":"fr","currency":"eur","openCartOnAdd":true,"useSideCart":false,"locales":{"fr":{"actions":{"checkout":"Valider le panier"}}},"templatesUrl":"path on your template file. Set file in the static folder, ex: '/snipcart/index.html'","innerHTML":"\n          <billing section=\"bottom\">\n          <snipcart-checkbox name=\"privacy-policy\" required></snipcart-checkbox>\n          <snipcart-label for=\"privacy-policy\">J'ai lu et approuvé les;<a href=\"https://couleursdefemmes.be/condition\">Conditions générales de vente</a></snipcart-label>          </billing>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Couleurs de femmes","short_name":"Couleurs de femmes","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2f2200d4b081f242d6b7c4b1eeeca06d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
